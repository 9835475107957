import { Desktop, Mobile } from "@/common/MediaQueries"
import { useHash } from "@/common/useHash"
import { scrollToSection } from "@/common/utils"
import colors from "@/theme/colors"
import styled from "@emotion/styled"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material"
import Image from "next/image"
import { usePathname, useRouter } from "next/navigation"
import { MouseEvent, useEffect, useState } from "react"
import SocialLinks from "./Common/SocialLinks"

const FooterWrapper = styled.footer`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 40px 160px 24px;
  background-color: rgba(242, 242, 242, 1);
`

const StyledGridItem = styled(Grid)``

const StyledLink = styled.a`
  &:visited {
    color: #262626;
  }
`

const StyledFooterLink = styled.a`
  text-decoration: none;
  color: #000000;
  font-size: 12px;
  font-weight: 700;
`
const navigationMobile = [
  {
    title: "לקראת רכישה",
    items: [
      {
        title: "תיאום נסיעת מבחן",
        link: "https://www.bmw.co.il/he/fastlane/Request_a_test_drive.html",
      },
      {
        title: "זמינות רכבים במלאי",
        link: "/inventory",
      },
      {
        title: "שאלות ותשובות",
        link: "/#faq",
      },
    ],
  },
  {
    title: "שירות",
    items: [
      {
        title: "צור קשר",
        link: "https://www.bmw.co.il/he/topics/offers-and-services/Contact-us.html",
      },
      {
        title: "מרכזי שירות",
        link: "https://www.bmw.co.il/he/topics/offers-and-services/service-center.html",
      },
      {
        title: "Recall",
        link: "https://www.bmw.co.il/he/topics/offers-and-services/Recall.html",
      },
      {
        title: "מחירון מוצרי תעבורה",
        link: "https://www.bmw.co.il/he/topics/offers-and-services/parts-price-list.html",
      },
    ],
  },
  {
    title: "מידע שימושי",
    items: [
      {
        title: "מפת אתר",
        link: "https://www.bmw.co.il/he/footer/sitemap.html",
      },
      {
        title: "הצהרת נגישות",
        link: "https://www.bmw.co.il/he/topics/about-us/_-_-_-_.html?",
      },
      {
        title: "תנאי שימוש",
        link: "https://www.bmw.co.il/he/footer/footer-section/Terms-of-service.html",
      },
      {
        title: "מדיניות פרטיות",
        link: "https://www.bmw.co.il/he/footer/footer-section/Privacy-policy.html",
      },
    ],
  },
]
const navigationServicesDesktopLinks = [
  {
    title: "צור קשר",
    link: "https://www.bmw.co.il/he/topics/offers-and-services/Contact-us.html",
  },
  {
    title: "מרכזי שירות",
    link: "https://www.bmw.co.il/he/topics/offers-and-services/service-center.html",
  },
  {
    title: "Recall",
    link: "https://www.bmw.co.il/he/topics/offers-and-services/Recall.html",
  },
  {
    title: "מחירון מוצרי תעבורה",
    link: "https://www.bmw.co.il/he/topics/offers-and-services/parts-price-list.html",
  },
]

const navigatioToBuyDesktopLinks = [
  {
    title: "תיאום נסיעת מבחן",
    link: "https://www.bmw.co.il/he/fastlane/Request_a_test_drive.html",
  },
  {
    title: "זמינות רכבים במלאי",
    link: "/inventory",
  },
  {
    title: "שאלות ותשובות",
    link: "/#faq",
  },
]

const navigatioUsefulInfoDesktopLinks = [
  {
    title: "מפת אתר",
    link: "https://www.bmw.co.il/he/footer/sitemap.html",
  },
  {
    title: "הצהרת נגישות",
    link: "https://www.bmw.co.il/he/topics/about-us/_-_-_-_.html?",
  },
  {
    title: "תנאי שימוש",
    link: "https://www.bmw.co.il/he/footer/footer-section/Terms-of-service.html",
  },
  {
    title: "מדיניות פרטיות",
    link: "https://www.bmw.co.il/he/footer/footer-section/Privacy-policy.html",
  },
]

function Footer() {
  const [defaultExpanded, setDefaultExpanded] = useState(false)
  useEffect(() => {
    setDefaultExpanded(false)
  }, [])
  const router = useRouter()
  const hash = useHash()
  const pathname = usePathname()

  const navigataToPage = (
    e: MouseEvent<HTMLAnchorElement>,
    item: {
      title: string
      link: string
    }
  ) => {
    e.preventDefault()
    if (item.link === "/#faq") {
      if (hash === "#faq" || pathname === "/") {
        e.preventDefault()
        scrollToSection("faq")
      } else {
        router.push(item.link)
      }
    } else {
      router.push(item.link)
    }
  }
  return (
    <>
      <Desktop>
        <FooterWrapper>
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            alignItems="flex-start"
            sx={{ maxWidth: "1200px" }}
          >
            <StyledGridItem xs={12} md={3}>
              <Typography
                sx={{ color: "#262626" }}
                mt={1}
                fontSize={14}
                fontWeight={300}
              >
                לקראת רכישה
              </Typography>
              <List
                role="presentation"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                {navigatioToBuyDesktopLinks.map((item, i) => (
                  <StyledLink
                    onClick={(e) => navigataToPage(e, item)}
                    href={item.link}
                    key={i}
                    style={{ textDecoration: "none" }}
                  >
                    <ListItem sx={{ padding: "0" }}>
                      <ListItemText sx={{ margin: "0" }}>
                        <Typography
                          sx={{ color: "#262626" }}
                          mt={1}
                          fontSize={14}
                        >
                          {item.title}
                        </Typography>
                      </ListItemText>
                    </ListItem>{" "}
                  </StyledLink>
                ))}
              </List>
            </StyledGridItem>
            <StyledGridItem xs={12} md={3}>
              <Typography
                color={"#262626"}
                mt={1}
                fontSize={14}
                fontWeight={300}
              >
                מידע שימושי
              </Typography>
              <List role="presentation">
                {navigatioUsefulInfoDesktopLinks.map((item, i) => (
                  <StyledLink
                    style={{ textDecoration: "none" }}
                    target="_blank"
                    href={item.link}
                    key={i}
                  >
                    <ListItem sx={{ padding: "0", textAlign: "start" }}>
                      <ListItemText sx={{ margin: "0" }}>
                        <Typography color={"#262626"} mt={1} fontSize={14}>
                          {item.title}
                        </Typography>
                      </ListItemText>
                    </ListItem>{" "}
                  </StyledLink>
                ))}
              </List>
            </StyledGridItem>
          </Grid>
        </FooterWrapper>
      </Desktop>
      <Mobile>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            paddingBottom: "250px",
          }}
        >
          {navigationMobile.map((nav, index) => (
            <Accordion key={index} defaultExpanded={defaultExpanded}>
              <AccordionSummary
                style={{ backgroundColor: "transparent" }}
                expandIcon={
                  <Image
                    alt="arrow down"
                    src="/assets/arrowDown.svg"
                    width={20}
                    height={20}
                  />
                }
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography fontSize={14} fontWeight={700}>
                  {nav.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {nav.items.map((item, i) => (
                  <StyledLink
                    onClick={(e) => navigataToPage(e, item)}
                    href={item.link}
                    key={i}
                    style={{ textDecoration: "none", color: colors.black }}
                  >
                    <Typography mt={2} fontSize={14} fontWeight={700}>
                      {item.title}
                    </Typography>
                  </StyledLink>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
          <SocialLinks />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "35px",
              paddingTop: "35px",
            }}
          >
            <Typography mt={2} fontSize={16} fontWeight={400}>
              BMW WG 2024
            </Typography>
            <Box sx={{ display: "flex", gap: "8px" }}>
              <StyledFooterLink href="  https://www.bmw.co.il/he/footer/footer-section/Terms-of-service.html">
                תנאי שימוש
              </StyledFooterLink>
              <StyledFooterLink href="https://www.bmw.co.il/he/footer/footer-section/Privacy-policy.html">
                מדיניות פרטיות
              </StyledFooterLink>
            </Box>
          </Box>
        </Box>
      </Mobile>
    </>
  )
}

export default Footer
