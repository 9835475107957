import { headerTitles } from "@/app/config/headerConfig"
import { linksConfig } from "@/app/config/linksConfig"
import { Desktop, Mobile, useIsDesktop } from "@/common/MediaQueries"
import { ContentMenuType, navigationLinks } from "@/common/navigationLinks"
import colors from "@/theme/colors"
import sizes from "@/theme/sizes"
import theme from "@/theme/theme"
import { CSSObject } from "@emotion/react"
import styled from "@emotion/styled"
import {
  Box,
  Button,
  Collapse,
  Drawer,
  IconButton,
  Typography,
} from "@mui/material"
import dynamic from "next/dynamic"
import Image from "next/image"
import { usePathname } from "next/navigation"
import { useState } from "react"
import DesktopDrawer from "./Common/Drawer/DesktopDrawer"
import MobileMenuDrawer from "./Common/Drawer/MobileMenuDrawer"

const DesktopInnerDrawer = dynamic(
  () => import("./Common/Drawer/DesktopInnerDrawer"),
  { ssr: false }
)

const StyledHeaderWrapper = styled(Box)(
  () =>
    ({
      display: "flex",
      zIndex: 3,
      position: "sticky",
      top: 0,
      justifyContent: "space-between",
      alignItems: "center",
      padding: sizes.header.paddingHeader,
      backgroundColor: colors.white,
      height: sizes.header.headerHeight,
      [theme.breakpoints.down("sm")]: {
        padding: "0px",
      },
    }) as CSSObject
)

const ItemWrapper = styled(Box)(
  () =>
    ({
      display: "flex",
      textDecoration: "none",
      marginRight: 20,
      alignItems: "center",
      height: "100%",
    }) as CSSObject
)

const ItemWrapperDesktop = styled(Box)(
  () =>
    ({
      display: "flex",
      textDecoration: "none",
      marginRight: 20,
      alignItems: "center",
      height: "100%",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    }) as CSSObject
)
const Section = styled(Box)(
  () =>
    ({
      display: "flex",
      flexDirection: "row",
      gap: 20,
      height: "100%",
      alignItems: "center",
    }) as CSSObject
)

const StyledCollapse = styled(Collapse)`
  .wrapper {
    height: 476px;
  }
`

const Header = () => {
  const [open, setOpen] = useState(false)
  const [openDesktopDrawer, setOpenDesktopDrawer] = useState<number>(0)
  const [desktopDrawerLinks, setDesktopDrawerLinks] = useState<
    ContentMenuType | undefined
  >()
  const [openInner, setOpenInner] = useState(false)
  const pathname = usePathname()

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen)
    setOpenInner(false)
  }

  const toggleDesktopDrawer = (title: string, index: number) => () => {
    setDesktopDrawerLinks(navigationLinks.find((link) => link.title === title))
    if (index === openDesktopDrawer) {
      setOpenDesktopDrawer(0)
    } else {
      setOpenDesktopDrawer(index)
    }
  }

  const StyledLink = styled.a<{ isActive?: boolean }>`
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    border-bottom: ${({ isActive }) =>
      isActive && !openDesktopDrawer
        ? `4px solid ${colors.brandBlue}`
        : "none"};
    .MuiTypography-root {
      font-weight: ${({ isActive }) =>
        isActive && !openDesktopDrawer ? 500 : 300};
      color: ${({ isActive }) => (isActive ? colors.black : colors.grey)};
    }

    :hover {
      .MuiTypography-root {
        color: ${colors.black};
        font-weight: 500;
      }
      border-bottom: 4px solid ${colors.brandBlue};
    }
  `
  return (
    <StyledHeaderWrapper>
      <Section>
        <Mobile>
          <a
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
            href="https://www.bmw.co.il/he/index.html"
          >
            <Image
              src="/assets/logo.svg"
              alt="logo bmw"
              width={sizes.images.logo_sm.width}
              height={sizes.images.logo_sm.height}
            />
          </a>
        </Mobile>
        <Desktop>
          <a
            style={{ textDecoration: "none" }}
            rel="noopener noreferrer"
            href="https://www.bmw.co.il/he/index.html"
          >
            <Image
              src="/assets/logo.svg"
              alt="logo bmw"
              width={sizes.images.logo_bg.width}
              height={sizes.images.logo_bg.height}
            />
          </a>
        </Desktop>

        <ItemWrapperDesktop gap={3}>
          {Object.entries(headerTitles).map((title, i) => {
            if (
              title[1] === "לקראת רכישה" ||
              title[1] === "שירות" ||
              title[1] === "העולם שלנו"
            ) {
              return (
                <Button
                  sx={{
                    padding: 0,
                    height: "100%",
                    ":hover": {
                      backgroundColor: "transparent",
                      "& .MuiTypography-root": {
                        color: colors.black,
                        fontWeight: 500,
                      },
                    },
                    "& .MuiTypography-root": {
                      fontWeight: 300,
                    },
                  }}
                  onClick={toggleDesktopDrawer(title[1], i)}
                  key={title[1]}
                >
                  <Typography
                    color={colors.grey}
                    sx={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      color: "#666",
                      fontWeight: "500 !important",
                      ":hover": {
                        borderBottom: `4px solid ${colors.brandBlue}`,
                      },
                    }}
                    fontSize={16}
                  >
                    {title[1]}
                  </Typography>
                </Button>
              )
            }
            return (
              <StyledLink
                key={title[1]}
                href={`${linksConfig[title[0] as keyof typeof linksConfig]}`}
                rel="noopener noreferrer"
                isActive={
                  pathname === linksConfig[title[0] as keyof typeof linksConfig]
                }
              >
                <Typography
                  sx={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    color: "#666!important",
                    fontWeight: "500 !important",
                  }}
                >
                  {title[1]}
                </Typography>
              </StyledLink>
            )
          })}
        </ItemWrapperDesktop>
        <StyledCollapse
          sx={{
            width: "100%",
            position: "absolute",
            top: "96px",
            right: "0px",
            backgroundColor: colors.white,
            paddingTop: "24px",
            [theme.breakpoints.down("sm")]: {
              display: "none",
            },
          }}
          in={openDesktopDrawer !== 0}
          classes={{ wrapper: "wrapper" }}
        >
          <DesktopDrawer>
            <DesktopInnerDrawer navigationLinks={desktopDrawerLinks} />
          </DesktopDrawer>
        </StyledCollapse>
      </Section>
      <Section>
        <ItemWrapper gap={3}>
          {!useIsDesktop() && (
            <>
              <IconButton
                aria-label="לחץ לפתיחת/סגירת תפריט"
                aria-expanded={open}
                onClick={toggleDrawer(true)}
              >
                <Image
                  src="/assets/burger.svg"
                  alt="תפריט"
                  width={24}
                  height={24}
                />
              </IconButton>
              <Drawer
                PaperProps={{ sx: { width: "100%" } }}
                anchor="right"
                open={open}
                onClose={toggleDrawer(false)}
              >
                <MobileMenuDrawer
                  openInner={openInner}
                  setOpenInner={setOpenInner}
                  toggleDrawer={toggleDrawer(false)}
                />
              </Drawer>
            </>
          )}
        </ItemWrapper>
      </Section>
    </StyledHeaderWrapper>
  )
}

export default Header
